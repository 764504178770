import "js/lib/immer";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "js/store";
import { ThemeModeProvider } from "@riedel/mui-theme-react";
import App from "views/App";


console.log("store initial", store.getState());

const root = document.getElementById("root") ;

ReactDOM.createRoot(root!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeModeProvider>
        <App />
      </ThemeModeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
