import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const Version = () => {
  return (
    <Box sx={{ p: 2, width: "100%" }}>
      <Container>
        <Typography variant="body2" color="text.secondary" align="right">
          Version {process.env.REACT_APP_VERSION}
        </Typography>
      </Container>
    </Box>
  );
};

export default Version;
