import { createReducer } from "@reduxjs/toolkit";
import { getProjects, getProjectsPermission } from "js/actions/projects";
import {ProjectsPermissions, Project} from "js/lib/projectType"

interface Projects {
  readonly projects: Project;
  readonly projectsPermission: ProjectsPermissions;
  
}
 
const emptyState: Projects = {
  projects: [],
  projectsPermission: {
    is_allowed_to_edit_targets: false,
    update_devices_in_projects: null,
    view_devices_in_projects: null,
  },
};

const projects = createReducer(emptyState, (builder) => {
  builder.addCase(getProjects.fulfilled, (state, action) => {
    state.projects = action.payload;
  });
  builder.addCase(getProjectsPermission.fulfilled, (state, action) => {
    state.projectsPermission = action.payload;
  });
});

export default projects;
