import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EditDeviceDialog from "components/EditDeviceDialog";
import ResetUpdateStateDialog from "components/ResetUpdateStateDialog";
import UpdateDialog, { prepareUpdateDialog } from "components/UpdateDialog";
import { getDevices } from "js/actions/devices";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { DeviceInfo } from "js/lib/deviceType";
import { useEffect, useState } from "react";
import UpdateStateIcon, {
  UpdateStateIconGridFilterOperator,
} from "components/UpdateStateIcon";
import DeviceSearchBar from "components/DeviceSearchBar";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import RelativeTime from "components/RelativeTime";
import React from "react";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import BundleVersion from "components/BundleVersion";

const columns: GridColDef<DeviceInfo>[] = [
  { field: "host_name", headerName: "Name", flex: 1 },
  { field: "description", headerName: "Description", flex: 2 },
  {
    field: "serial_number",
    headerName: "Serial Number",
    flex: 1,
  },
  {
    field: "inventory_number",
    headerName: "Inventory Number",
    flex: 1,
  },
  {
    field: "project",
    headerName: "Project",
    maxWidth: 100,
    flex: 1,
  },
  {
    field: "last_online_at",
    headerName: "Online",
    align: "center",
    maxWidth: 120,
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {
      return <RelativeTime time={params.value} />;
    },
  },
  {
    field: "booted_version",
    headerName: "Running Version",
    align: "center",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {
      return <BundleVersion version={params.value} />;
    },
  },
  {
    field: "current_update_version",
    headerName: "Current Version",
    align: "center",
    renderCell: (params) => {
      return <BundleVersion version={params.value} />;
    },
  },
  {
    field: "current_update_state",
    headerName: "State",
    align: "center",
    filterOperators: UpdateStateIconGridFilterOperator,
    renderCell: (params) => {
      return <UpdateStateIcon state={params.value} />;
    },
  },
  {
    field: "logs",
    headerName: "Logs",
    align: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/updates-log/${params.row.serial_number}`}
          >
            <LaunchIcon />
          </Button>
        </Stack>
      );
    },
  },
];

const Devices = () => {
  const dispatch = useAppDispatch();
  const devices = useAppSelector((state) => state.devices);
  const [selected, setSelected] = useState<DeviceInfo[]>([]);
  const [dialogUpdateOpen, setDialogUpdateOpen] = useState(false);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);
  const [dialogResetStateOpen, setDialogResetStateOpen] = useState(false);

  const getApplySearch = (searchTerm: string) => {
    return (params: GridCellParams): boolean => {
      return Object.keys(params.row).some((key) =>
        params.row[key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    };
  };

  const data = React.useMemo(
    () =>
      devices.devices.map((column: DeviceInfo) => {
        return {
          ...column,
          getApplyQuickFilterFn: getApplySearch,
        };
      }),
    [devices.devices]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getDevices());
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (dialogEditOpen || dialogUpdateOpen || dialogResetStateOpen) return;
    dispatch(getDevices());
  }, [dispatch, dialogUpdateOpen, dialogEditOpen, dialogResetStateOpen]);

  useEffect(() => {
    //ensure all selected devices exists
    setSelected((s) =>
      devices.manageableDevices.filter(
        (d) => s.findIndex((v) => d.serial_number === v.serial_number) !== -1
      )
    );
  }, [devices]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Devices
      </Typography>
      <UpdateDialog
        open={dialogUpdateOpen}
        selected={selected}
        handleClose={() => {
          setDialogUpdateOpen(false);
        }}
      />
      <EditDeviceDialog
        open={dialogEditOpen}
        selected={selected}
        handleClose={() => {
          setDialogEditOpen(false);
        }}
      />
      <ResetUpdateStateDialog
        open={dialogResetStateOpen}
        selected={selected}
        handleClose={() => setDialogResetStateOpen(false)}
      />
      <Paper>
        <DataGrid
          autoHeight={true}
          sx={{ width: "100%", minHeight: "300px" }}
          pageSizeOptions={[10, 20, 50, 100]}
          columns={columns}
          rows={data}
          getRowId={(row) => row.serial_number}
          checkboxSelection={true}
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = devices.manageableDevices.filter((row) =>
              selectedIDs.has(row.serial_number)
            );
            setSelected(selectedRows);
          }}
          components={{ Toolbar: DeviceSearchBar }}
          isRowSelectable={(params) => {
            return params.row.manageable;
          }}
        />
        <Stack spacing={2} direction="row" justifyContent="end" p={3}>
          <Button
            color="warning"
            variant="contained"
            onClick={() => setDialogResetStateOpen(true)}
          >
            Reset State of Selected
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setDialogEditOpen(true)}
          >
            Edit Selected
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              prepareUpdateDialog(dispatch);
              setDialogUpdateOpen(true);
            }}
          >
            Update Selected
          </Button>
        </Stack>
      </Paper>
    </>
  );
};

export default Devices;
