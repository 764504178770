import { createAction } from "@reduxjs/toolkit";
import { UpdateInsertErrorEntry } from "js/lib/updateType";

export const reasonInvalidToken = "invalid token";
export const editIntent = "edit devices";
export const resetIntent = "reset state"

export interface SimpleErrorData {
  message: string;
  intent?: string;
  errorList?: UpdateInsertErrorEntry[];
}

export interface RequestErrorData extends SimpleErrorData {
  status: number;
  statusText: string;
}

export const getErrorID = (err: SimpleErrorData) => {
  return err.intent ?? err.message;
};

export class RequestError extends Error {
  readonly error: RequestErrorData;
  constructor(err: RequestErrorData) {
    super(err.message);
    this.name = "RequestError";
    this.error = err;
  }
}

export const fetchFailed = createAction<RequestErrorData>("fetchFailed");
export const setError = createAction<SimpleErrorData>("setError");
export const clearError = createAction<SimpleErrorData>("clearError");
