import { HashRouter, Route, Routes } from "react-router-dom";
import { getTheme, useThemeMode } from "@riedel/mui-theme-react";
import { ThemeProvider } from "@mui/material";
import Container from "@mui/material/Container";
import Header from "components/Header";
import ErrorBoundary from "components/errorBoundary";
import NotFound from "views/NotFound";
import Login from "./Login";
import UpdateLogs from "./UpdateLogs";
import Devices from "./Devices";
import { AuthProvider } from "js/lib/AuthContext";
import Bundles from "./Bundles";
import Version from "components/Version";
import { CssBaseline } from "@mui/material";
import ProtectedPage from "components/ProtectedPage";

const App = () => {
  const { themeMode } = useThemeMode();
  const theme = getTheme(themeMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <div>
          <Header />
          <ErrorBoundary>
            <AuthProvider>
              <Container maxWidth="lg">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <ProtectedPage>
                        <Devices />
                      </ProtectedPage>
                    }
                  />
                  <Route
                    path="/bundles"
                    element={
                      <ProtectedPage>
                        <Bundles />
                      </ProtectedPage>
                    }
                  />
                  <Route
                    path="/updates-log/:id"
                    element={
                      <ProtectedPage>
                        <UpdateLogs />
                      </ProtectedPage>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </Container>
            </AuthProvider>
          </ErrorBoundary>
          <Version />
        </div>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
