import { RequestError, RequestErrorData } from "js/actions/errors";
import { reasonInvalidToken } from "js/actions/errors";

export default async function request<T>(
  url: string,
  config: RequestInit
): Promise<T> {
  const response = await fetch("/api/v1" + url, config);
  if (!response.ok) {
    if (response.status === 401) {
      throw new RequestError({
        message: reasonInvalidToken,
        statusText: response.statusText,
        status: response.status,
      });
    }
    if (response.status === 502) {
      throw new RequestError({
        message: "request failed please try again",
        statusText: response.statusText,
        status: response.status,
      });
    } else if (response.headers.get("Content-Type") === "application/json") {
      let error = await response.json();
      throw new RequestError({
        message: error.message,
        statusText: response.statusText,
        status: response.status,
        errorList: error.detail,
      });
    }
    throw new RequestError({
      message: await response.text(),
      statusText: response.statusText,
      status: response.status,
    });
  }
  if (response.headers.get("Content-Type") !== "application/json") {
    console.error("request returned non-json content", url);
  }
  return (await response.json()) as T;
}

export interface RequestState {
  pending: boolean;
  fulfilled: boolean;
  error?: RequestErrorData;
}
