import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from "@mui/material";
import { DeviceInfo } from "js/lib/deviceType";
import { UpdateInsertErrorMap } from "js/reducers/update";
import { useTheme } from '@mui/material/styles';

type Props = {
  msg: string;
  updateErrorListFiltered: UpdateInsertErrorMap;
  devices: DeviceInfo[];
};

function IncompTable({ msg, updateErrorListFiltered, devices }: Props) {
  const isDarkMode = useTheme().palette.mode === 'dark';
  function getListText(serial_number: string, devices: DeviceInfo[]) {
    const hostname = devices.find(
      (d) => d.serial_number === serial_number
    )?.host_name;
    return {
      hostname: hostname,
      serial_number: serial_number,
    };
  }

  return (
    <TableContainer
      sx={{
        border: `1px solid ${isDarkMode ? "#333" : "#e0e0e0"}`,
        borderRadius: "5px",
        margin: "16px 0",
      }}
    >
      <Table>
        <TableHead  sx={{ backgroundColor: isDarkMode ? "#2b2b2b" : "#f5f5f5" }}>
          <TableRow>
            <TableCell sx={{ textAlign: "center" }}>
              <Typography variant="subtitle1">Device Name</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <Typography variant="subtitle1">Serial Number</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {updateErrorListFiltered[msg].map((v, i) => (
          <TableBody key={v.serial_number}>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  borderBottom: "none",
                  borderTop: i === 0 ? "1px solid #e0e0e0" : "none",
                }}
              >
                <Typography variant="subtitle1">
                  {getListText(v.serial_number, devices).hostname
                    ? getListText(v.serial_number, devices).hostname
                    : "..."}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  borderBottom: "none",
                  borderTop: i === 0 ? "1px solid #e0e0e0" : "none",
                }}
              >
                <Typography variant="subtitle1">
                  {getListText(v.serial_number, devices).serial_number}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}

export default IncompTable;
