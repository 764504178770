import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "js/lib/AuthContext";

function ProtectedPage({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { isAuthenticated, logOut, isLoading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      logOut(location.pathname);
    }
  }, [isAuthenticated, isLoading, location.pathname, logOut]);

  return <>{isAuthenticated ? children : null}</>;
}

export default ProtectedPage;
