import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "js/hooks";
import React from "react";
import { getProjects } from "js/actions/projects";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  setDeviceInfos: (project: any) => void;
  defaultValue?: string;
  project?: string;
};

const DropDowMenu = ({ defaultValue, project, setDeviceInfos }: Props) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.projects.projects);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (!open) return;
    if (open) {
      setLoading(true);
      dispatch(getProjects());
      setLoading(false);
    }
  }, [dispatch, open]);

  const handleChange = (event: any, newValue: string | null) => {
    if (newValue && newValue.length > 0) {
      setDeviceInfos((prev: any) => ({
        ...prev,
        project: newValue,
      }));
    }
  };

  return (
    <>
      <Autocomplete
        autoHighlight
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        onInputChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={project}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option}
        options={projects}
        sx={{ width: 250 }}
        freeSolo
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select or Type a Project"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default DropDowMenu;
