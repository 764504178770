import { Box, Typography } from "@mui/material";
import LogProgress from "components/LogProgress";
import { DeviceUpdateLog } from "js/lib/deviceType";
import React, { useEffect, useState } from "react";

type Props = {
  logEntry: DeviceUpdateLog;
};

const LogEntry = (props: Props) => {
  const { logEntry } = props;
  const { message, percentage, timestamp } = logEntry;
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const readableDate = new Date(timestamp).toLocaleString();

  useEffect(() => {
    // just a quick way to check if the message contains an error or success :X
    const checkMessage = (message: string) => {
      if (percentage === -1) {
        setError(true);
        setSuccess(false);
      }
      if (message.includes("failed")) {
        setError(true);
        setSuccess(false);
      } else
      if (message.includes("Installing done") || message.includes("Updating slots done")) {
        setSuccess(true);
        setError(false);
      } else {
        setSuccess(false);
        setError(false);
      }
    };
    checkMessage(message);
  }, [message, error, success, percentage]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          p: 1,
        }}
      >
        <LogProgress progress={percentage} error={error} success={success} />
        <Typography variant="body2" color="success" sx={{ wordBreak: "break-word", width: 400 }}>
          {message}
        </Typography>
        <Typography variant="body2" color="success" sx={{
          marginLeft: "auto",
        }}>
          {readableDate}
        </Typography >
      </Box>
    </>
  );
}




export default LogEntry;
