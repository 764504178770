export interface UpdateInfo {
  id: number;
  version: string;
  host_name: string;
  serial_number: string;
  assigned_by: string;
  assigned_at: string;
  finished_at: string | null;
  started_at: string | null;
  state: string;
  last_state_update_at: string;
}

export enum UpdateInsertErrorEntryMessage {
  NO_PERMISSION = "no permission on this device",
  NO_BUNDLE = "no suitable bundle found",
  WRONG_STATE = "device is already running another update",
  NO_BUNDLE_FILE = "no bundle file found",
}

export interface UpdateInsertErrorEntry {
  serial_number: string;
  message: UpdateInsertErrorEntryMessage;
}
