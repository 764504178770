import { createReducer } from "@reduxjs/toolkit";
import { getBundles } from "js/actions/bundles";
import { BundleInfo, Version } from "js/lib/bundleType";

interface Bundles {
  readonly bundles: BundleInfo[];
  readonly versions: Version[];
}

const emptyState: Bundles = {
  bundles: [],
  versions: [],
};

const devices = createReducer(emptyState, (builder) => {
  builder.addCase(getBundles.fulfilled, (state, action) => {
    state.bundles = action.payload;
    let versions = action.payload
      .filter((bundle) => bundle.file_name !== null)
      .map((bundle) => {
        return { version: bundle.version, arch: bundle.arch };
      });
    state.versions = versions.filter((v, i, a) => a.indexOf(v) === i);
  });
});

export default devices;
