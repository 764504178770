import { Stack, Typography, ListItem, ListItemText } from "@mui/material";
import { DeviceInfo } from "js/lib/deviceType";

type Props = {
  updatableDevices: DeviceInfo[];
};

const CompatibleDevices = (props: Props) => {
  const { updatableDevices } = props;
  return (
    <Stack spacing={2} sx={{ marginTop: "20px" }}>
      <Typography variant="subtitle1">
        Do you want to continue to update the other device(s)? :
        {updatableDevices.map((device) => (
          <ListItem key={device.serial_number}>
            <ListItemText
              primary={`- Device ${
                device.host_name ? device.host_name : device.serial_number
              }`}
              secondary={`Architecture: ${device.arch || "N/A"}`}
            />
          </ListItem>
        ))}
      </Typography>
    </Stack>
  );
};

export default CompatibleDevices;
