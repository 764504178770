import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { logOut } from "./auth";

interface AuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  logOut: (next_url: string) => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuth must be used within the AuthProvider wrapper context"
    );
  }
  return context;
};

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);
        const response = await fetch("/auth/check");
        setIsAuthenticated(response.status === 200);
        setIsLoading(false);
      } catch (e) {
        setIsAuthenticated(false);
        setIsLoading(false);
        console.log(e);
        throw new Error("Error checking authentication");
      }
    };
    checkAuth();
  }, [isAuthenticated, location.pathname, navigate, searchParams]);

  useEffect(() => {
    const next_url = searchParams.get("next_url");
    if (next_url !== null) {
      navigate(next_url);
    }
  }, [isAuthenticated, navigate, searchParams]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};
