import { createAppAsyncThunk } from "js/hooks";
import { BundleInfo } from "js/lib/bundleType";
import request from "js/lib/fetch";
import { fetchFailed, RequestError, RequestErrorData } from "./errors";

export const getBundles = createAppAsyncThunk<
  BundleInfo[],
  void,
  { rejectValue: RequestErrorData }
>("bundles/get", async (_, { dispatch, rejectWithValue }) => {
  const url = "/bundle";

  const options: RequestInit = {
    method: "GET",
  };
  try {
    const bundles = await request<BundleInfo[]>(url, options);
    return bundles;
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch bundle info" }));
    return rejectWithValue(error.error);
  }
});
