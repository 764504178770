import { createAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "js/hooks";
import { DeviceInfo, DeviceUpdate, DeviceUpdateLog } from "js/lib/deviceType";
import request from "js/lib/fetch";
import {
  editIntent,
  fetchFailed,
  RequestError,
  RequestErrorData,
  resetIntent,
} from "./errors";

export const getDevices = createAppAsyncThunk<
  DeviceInfo[],
  void,
  { rejectValue: RequestErrorData }
>("devices/get", async (_, { dispatch, rejectWithValue }) => {
  const url = "/devices";
  const options: RequestInit = {
    method: "GET",
  };
  try {
    const devices = await request<DeviceInfo[]>(url, options);
    return devices;
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch device info" }));
    return rejectWithValue(error.error);
  }
});

type DeviceUpdateLogWithDeviceSerial = {
  logs: DeviceUpdateLog[];
  serial: string | null;
};

async function requestLogs(
  url: string,
  config: RequestInit,
  serial: string
): Promise<DeviceUpdateLogWithDeviceSerial> {
  let logs = await request<DeviceUpdateLog[]>(url, config);
  return { logs, serial };
}

export const getDeviceLog = createAppAsyncThunk<
  DeviceUpdateLogWithDeviceSerial,
  string | null,
  { rejectValue: RequestErrorData }
>("devices/getLog", async (deviceSerial, { dispatch, rejectWithValue }) => {
  if (deviceSerial === null) {
    let empty: DeviceUpdateLogWithDeviceSerial = {
      logs: [],
      serial: deviceSerial,
    };
    return empty;
  }

  const url = `/devices/${deviceSerial}/update/logs`;
  const options: RequestInit = {
    method: "GET",
  };
  try {
    return await requestLogs(url, options, deviceSerial);
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(
      fetchFailed({ ...error.error, intent: "fetch device update log" })
    );
    return rejectWithValue(error.error);
  }
});

export const resetEditDevicesState = createAction(
  "devices/editDevice/resetState"
);

export const editDevices = createAppAsyncThunk<
  void,
  DeviceUpdate,
  { rejectValue: RequestErrorData }
>(
  "devices/editDevice",
  async (devicesUpdate, { dispatch, rejectWithValue }) => {
    const url = "/devices";
    try {
      await request<any>(url, {
        method: "PUT",
        body: JSON.stringify(devicesUpdate),
      });
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(fetchFailed({ ...error.error, intent: editIntent }));
      return rejectWithValue(error.error);
    }
  }
);

export const resetSetStateFailedOnDevicesState = createAction(
  "devices/setStateFailedOnDevices/resetState"
);

export const setStateFailedOnDevices = createAppAsyncThunk<
  void,
  string[],
  { rejectValue: RequestErrorData }
>(
  "devices/setStateFailedOnDevices",
  async (devices, { rejectWithValue, dispatch }) => {
    const url = "/devices";
    const update: DeviceUpdate = {
      serial_number: devices,
      set_state_failed: true,
    };
    try {
      await request<any>(url, {
        method: "PUT",
        body: JSON.stringify(update),
      });
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(fetchFailed({ ...error.error, intent: resetIntent }));
      return rejectWithValue(error.error);
    }
  }
);
