import { createAppAsyncThunk } from "js/hooks";
import request from "js/lib/fetch";
import { fetchFailed, RequestError, RequestErrorData } from "./errors";
import { Project, ProjectsPermissions } from "js/lib/projectType";

export const getProjects = createAppAsyncThunk<
  Project,
  void,
  { rejectValue: RequestErrorData }
>("api/projects", async (_, { rejectWithValue, dispatch }) => {
  const url = "/projects";
  const options: RequestInit = {
    method: "GET",
  };
  try {
    const response = await request<Project>(url, options);
    return response;
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch projects" }));
    return rejectWithValue(error.error);
  }
});

export const getProjectsPermission = createAppAsyncThunk<
  ProjectsPermissions,
  void,
  { rejectValue: RequestErrorData }
>("api/permissions", async (_, { rejectWithValue, dispatch }) => {
  const url = "/permissions";
  const options: RequestInit = {
    method: "GET",
  };
  try {
    const response = await request<ProjectsPermissions>(url, options);
    return response;
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(
      fetchFailed({ ...error.error, intent: "fetch projects permission" })
    );
    return rejectWithValue(error.error);
  }
});
