import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { DeviceInfo, DeviceUpdate, EmptyDevice } from "js/lib/deviceType";
import { useEffect, useState } from "react";
import { editDevices, resetEditDevicesState } from "js/actions/devices";
import { clearError, editIntent, setError } from "js/actions/errors";
import DropDowMenu from "components/DropDowMenu";
import { getProjectsPermission } from "js/actions/projects";

type EditDeviceDialogProps = {
  open: boolean;
  selected: DeviceInfo[];
  handleClose: () => void;
};

export type DeviceChanges = {
  project: boolean;
  description: boolean;
};

function EditDeviceDialog({
  open,
  selected,
  handleClose,
}: EditDeviceDialogProps) {
  const dispatch = useAppDispatch();
  const editRequest = useAppSelector((state) => state.devices.updateRequest);
  const [deviceInfos, setDeviceInfos] = useState<DeviceInfo>(EmptyDevice);
  const projectsPermission = useAppSelector(
    (state) => state.projects.projectsPermission
  );

  const [changes, setChanges] = useState<DeviceChanges>({
    project: false,
    description: false,
  });

  useEffect(() => {
    if(!open) return;
    dispatch(getProjectsPermission());
  }, [dispatch, open]);

  useEffect(() => {
    if (!open) return;
    // check select count on open
    if (selected.length === 0) {
      dispatch(
        setError({
          intent: editIntent,
          message: "Please select at least one Device for edit properties.",
        })
      );
      handleClose();
    } else {
      dispatch(clearError({ intent: editIntent, message: "" }));
      setDeviceInfos(selected[0]);
    }
    // avoid rerunning on every state change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, JSON.stringify(selected.map((s) => s.serial_number).sort())]);

  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 3,
    p: 3,
    width: "100%",
    justifyContent: "center",
  };

  const closeAndreset = () => {
    handleClose();
    setDeviceInfos(EmptyDevice);
    setChanges({
      project: false,
      description: false,
    });
  };


  return (
    <>
      <Snackbar
        open={editRequest.fulfilled}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(resetEditDevicesState());
        }}
      >
        <Alert
          onClose={() => {
            dispatch(resetEditDevicesState());
          }}
          severity="success"
        >
          Changed fields of selected devices.
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={closeAndreset}>
        <DialogTitle>Select the Fields to set their new value:</DialogTitle>
        <DialogContent>
          <Container sx={{ ...boxStyle, flexDirection: "column", width: 500 }}>
            <Box sx={boxStyle}>
              <Typography
                variant="body1"
                sx={{ textAlign: "left", width: 100 }}
              >
                Description:
              </Typography>{" "}
              <Checkbox
                checked={changes.description}
                onChange={(e) => {
                  setChanges((prev) => ({
                    ...prev,
                    description: e.target.checked,
                  }));
                }}
              />
              <TextField
                sx={{ width: 250 }}
                placeholder={deviceInfos.description}
                value={deviceInfos.description}
                onChange={(e) => {
                  setDeviceInfos((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
            </Box>
            {projectsPermission.is_allowed_to_edit_targets ? (
              <Box sx={boxStyle}>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "left", width: 100 }}
                >
                  Project:
                </Typography>
                <Checkbox
                  checked={changes.project}
                  onChange={(e) => {
                    setChanges((prev) => ({
                      ...prev,
                      project: e.target.checked,
                    }));
                  }}
                />
                <DropDowMenu
                  setDeviceInfos={setDeviceInfos}
                  defaultValue={deviceInfos.project}
                  project={deviceInfos.project}
                />
              </Box>
            ) : null}
          </Container>
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
          }}
        >
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              closeAndreset();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (!changes.description && !changes.project) {
                dispatch(
                  setError({
                    intent: editIntent,
                    message: "Please select at least one field to change.",
                  })
                );
                closeAndreset()
                return;
              }
              let devUpdate: DeviceUpdate = {
                serial_number: selected.map((v) => v.serial_number),
                set_state_failed: false,
              };
              if (changes.description) {
                devUpdate.description = deviceInfos.description;
              }
              if (changes.project) {
                devUpdate.project = deviceInfos.project;
              }
              dispatch(editDevices(devUpdate));
              closeAndreset()
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditDeviceDialog;
