// this deletes cookie and redirect to login page
export const logOut = async (next_url: string) => {
  try {
    await fetch("/auth/logout");
  } catch (err) {
    console.log(err);
    throw new Error("Error logging out");
  }
  window.location.href = "/auth/login?next_url=" + next_url;
};
