import { createAction } from "@reduxjs/toolkit";
import request from "js/lib/fetch";
import { UpdateInfo } from "js/lib/updateType";
import { fetchFailed, RequestError, RequestErrorData } from "./errors";
import { createAppAsyncThunk } from "js/hooks";
import { DeviceUpdateLog } from "js/lib/deviceType";

interface NewUpdate {
  version: string;
  serial_number: string[];
}

export const resetAddUpdateState = createAction("update/addUpdate/resetState");

export const addUpdate = createAppAsyncThunk<
  void,
  NewUpdate,
  { rejectValue: RequestErrorData }
>("update/addUpdate", async (update, { dispatch, rejectWithValue }) => {
  const url = "/update";
  try {
    await request<any>(url, {
      method: "POST",
      body: JSON.stringify(update),
    });
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "assign update" }));
    return rejectWithValue(error.error);
  }
});

export const getUpdates = createAppAsyncThunk<
  UpdateInfo[],
  void,
  { rejectValue: RequestErrorData }
>("update/get", async (_, { dispatch, rejectWithValue }) => {
  const url = "/update";

  const options: RequestInit = {
    method: "GET",
  };
  try {
    const updates = await request<UpdateInfo[]>(url, options);
    return updates;
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch update info" }));
    return rejectWithValue(error.error);
  }
});

// get update log by id
export const getUpdateLog = createAppAsyncThunk<
  DeviceUpdateLog[],
  number | undefined,
  { rejectValue: RequestErrorData }
>("update/getLogs", async (updateId, { dispatch, rejectWithValue }) => {
  if (updateId === undefined) {
    return [];
  }
  const url = "/update/" + updateId + "/logs";
  const options: RequestInit = {
    method: "GET",
  };
  try {
    const response = await request<DeviceUpdateLog[]>(url, options);
    return response;
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch update log" }));
    return rejectWithValue(error.error);
  }
});

export const clearUpdateLog = createAction("update/clearLogs");
