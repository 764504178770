import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Login = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Login
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Button variant="contained">
          <a href="/auth/login">Login with Riedel Account</a>
        </Button>
      </Box>
    </>
  );
};

export default Login;
