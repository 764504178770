import {
  List,
  ListItem,
  ListItemText,
  Chip,
  ListItemButton,
  Collapse,
} from "@mui/material";
import { DeviceInfo } from "js/lib/deviceType";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Update } from "./UpdateDialog";
import { useState } from "react";

type Props = {
  setSelectedVersion: (version: string) => void;
  updates: Update[];
  selectedDevices: DeviceInfo[];
  selectedVersion: string;
  incompatibleUpdates: Update[];
  compatibleUpdates: Update[];
  setUpdatableDevices: (devices: DeviceInfo[]) => void;
};



const UpdateSelect = (props: Props) => {
  const [updatesList, setUpdatesList] = useState(false);
  const {
    setSelectedVersion,
    selectedDevices,
    selectedVersion,
    incompatibleUpdates,
    compatibleUpdates,
    setUpdatableDevices,
  } = props;

  const handleUpdateSelect = (update: Update) => {
    // Find devices that can't be updated to the selected update
    // const unupdated = selectedDevices.filter(
    //   (device) => device.arch !== update.arch
    // );
    const updatable = selectedDevices.filter(
      (device) => device.arch === update.arch
    );

    setUpdatableDevices(updatable);
    setSelectedVersion(update.version);
  };

  return (
    <div>
      <List>
        <ListItemText primary="Compatible updates:" />
        {compatibleUpdates.map((update, index) => {
          const updatableDevices = selectedDevices.filter(
            (device) => device.arch === update.arch
          ).length;
          return (
            <ListItemButton
              key={index}
              onClick={() => handleUpdateSelect(update)}
              selected={selectedVersion === update.version}
            >
              <ListItemText
                primary={`Update ${update.version}`}
                sx={{
                  pr: 1.5,
                }}
              />
              {updatableDevices < selectedDevices.length ? (
                <Chip
                  label={`${updatableDevices}/${selectedDevices.length}`}
                  size="small"
                  color="primary"
                />
              ) : null}
            </ListItemButton>
          );
        })}
        {compatibleUpdates.length === 0 ? (
          <ListItem>
            <ListItemText primary="No compatible updates found" />
          </ListItem>
        ) : null}
      </List>
      <List>
        <ListItemButton onClick={() => setUpdatesList(!updatesList)}>
          <ListItemText primary="Other Updates:" />
          {updatesList ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={updatesList} timeout="auto" unmountOnExit>
          {incompatibleUpdates.map((update, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`Update ${update.version}`}
                secondary={`Architecture: ${update.arch}`}
              />
            </ListItem>
          ))}
        </Collapse>
      </List>
    </div>
  );
};

export default UpdateSelect;
