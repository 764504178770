import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { TypedActionCreator } from "@reduxjs/toolkit/dist/mapBuilders";
import {
  editDevices,
  getDevices,
  resetEditDevicesState,
  resetSetStateFailedOnDevicesState,
  setStateFailedOnDevices,
} from "js/actions/devices";
import { RequestErrorData } from "js/actions/errors";
import { DeviceInfo } from "js/lib/deviceType";
import { RequestState } from "js/lib/fetch";

interface Devices {
  readonly devices: DeviceInfo[];
  readonly manageableDevices: DeviceInfo[];
  readonly updateRequest: RequestState;
  readonly setResetRequest: RequestState;
}

const emptyState: Devices = {
  devices: [],
  manageableDevices: [],
  updateRequest: {
    pending: false,
    fulfilled: false,
  },
  setResetRequest: {
    pending: false,
    fulfilled: false,
  },
};

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};

const handleRequest = function <
  P extends keyof PickByType<Devices, RequestState>,
  A,
  B
>(
  builder: ActionReducerMapBuilder<Devices>,
  thunk: AsyncThunk<A, B, { rejectValue: RequestErrorData }>,
  name: P
) {
  builder.addCase(thunk.pending, (state, action) => {
    state[name].pending = true;
    state[name].fulfilled = false;
    state[name].error = undefined;
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state[name].pending = false;
    state[name].fulfilled = true;
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state[name].pending = false;
    state[name].fulfilled = false;
    state[name].error = action.payload;
  });
};

const handleRequestReset = function <
  P extends keyof PickByType<Devices, RequestState>
>(
  builder: ActionReducerMapBuilder<Devices>,
  thunk: TypedActionCreator<string>,
  name: P
) {
  builder.addCase(thunk, (state, action) => {
    state[name].pending = false;
    state[name].fulfilled = false;
    state[name].error = undefined;
  });
};

const devices = createReducer(emptyState, (builder) => {
  builder.addCase(getDevices.fulfilled, (state, action) => {
    state.devices = action.payload;
    state.manageableDevices = action.payload.filter((v) => v.manageable);
  });
  handleRequest(builder, editDevices, "updateRequest");
  handleRequest(builder, setStateFailedOnDevices, "setResetRequest");
  handleRequestReset(builder, resetEditDevicesState, "updateRequest");
  handleRequestReset(
    builder,
    resetSetStateFailedOnDevicesState,
    "setResetRequest"
  );
});

export default devices;
