import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export const NavLinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, "to"> & {
    href: NavLinkProps["to"];
    className: string;
  }
>((props, ref) => {
  const { href, className, ...other } = props;
  return (
    <NavLink
      ref={ref}
      to={href}
      className={({ isActive }) =>
        isActive ? className + " Mui-selected" : className
      }
      {...other}
    />
  );
});

export const NavLinkEndBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, "to"> & {
    href: NavLinkProps["to"];
    className: string;
  }
>((props, ref) => {
  const { href, className, ...other } = props;
  return (
    <NavLink
      ref={ref}
      to={href}
      className={({ isActive }) =>
        isActive ? className + " Mui-selected" : className
      }
      end
      {...other}
    />
  );
});
