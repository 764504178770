import { configureStore } from "@reduxjs/toolkit";
import reducer from "js/reducers/index";
import unauthorizedErrorMiddleware from "./lib/authErrorMiddleware";

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: { ignoreState: true } }).concat(
      unauthorizedErrorMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
