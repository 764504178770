import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from "@mui/material";
import UpdateSelect from "components/UpdateSelect";
import { getBundles } from "js/actions/bundles";
import { addUpdate, resetAddUpdateState } from "js/actions/update";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { useCallback, useEffect, useState } from "react";
import { DeviceInfo } from "js/lib/deviceType";
import { clearError, setError } from "js/actions/errors";
import {
  resetSetStateFailedOnDevicesState,
  setStateFailedOnDevices,
} from "js/actions/devices";
import { AppDispatch } from "js/store";
import React from "react";
import { Version } from "js/lib/bundleType";
import CompatibleDevices from "./CompatibleDevices";
import IncompTable from "./IncompTable";
import { UpdateInsertErrorEntryMessage } from "js/lib/updateType";

type UpdateDialogProps = {
  open: boolean;
  selected: DeviceInfo[];
  handleClose: () => void;
};

export interface Update extends Version {
  score?: number;
}

const updateIntent = "assign update";

export function prepareUpdateDialog(dispatch: AppDispatch) {
  dispatch(resetSetStateFailedOnDevicesState());
  dispatch(resetAddUpdateState());
}

function UpdateDialog({ open, selected, handleClose }: UpdateDialogProps) {
  const dispatch = useAppDispatch();
  const devices = useAppSelector((state) => state.devices.devices);
  const addRequest = useAppSelector((state) => state.update.addRequest);
  const resetRequest = useAppSelector((state) => state.devices.setResetRequest);
  const versions = useAppSelector((state) => state.bundles.versions);
  const updateErrorList = useAppSelector(
    (state) => state.update.updateInsertError
  );
  const updateErrorListFiltered = useAppSelector(
    (state) => state.update.updateInsertErrorFiltered
  );

  const closeAndResetState = useCallback(() => {
    handleClose();
    // reset the state, after we consumed it
    dispatch(resetAddUpdateState());
  }, [handleClose, dispatch]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [compatibleUpdates, setCompatibleUpdates] = useState<Update[]>([]);
  const [incompatibleUpdates, setIncompatibleUpdates] = useState<Update[]>([]);
  const [updatableDevices, setUpdatableDevices] = useState<DeviceInfo[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<DeviceInfo[]>([]);

  useEffect(() => {
    if (selectedDevices.length > 0) {
      // Determine the most common architecture among devices
      const archCounts: { [key: string]: number } = {};
      selectedDevices.forEach((device) => {
        if (archCounts[device.arch]) {
          archCounts[device.arch] += 1;
        } else {
          archCounts[device.arch] = 1;
        }
      });
      const mostCommonArch = Object.keys(archCounts).reduce((a, b) =>
        archCounts[a] > archCounts[b] ? a : b
      );

      // Separate updates into compatible and incompatible
      const compatible: Update[] = [];
      const incompatible: Update[] = [];
      versions.forEach((update: Update) => {
        if (update.arch === mostCommonArch) {
          compatible.push(update);
        } else {
          incompatible.push(update);
        }
      });

      // Sort compatible updates by compatibility score
      const sortedCompatible = compatible
        .map((update) => {
          const updatableDevices = selectedDevices.filter(
            (device) => device.arch === update.arch
          ).length;
          const deviceCount = selectedDevices.length;
          const x = updatableDevices;
          const y = deviceCount;
          const score = x / y;
          return { ...update, score };
        })
        .sort((a, b) => b.score! - a.score!);
      setCompatibleUpdates(sortedCompatible);
      setIncompatibleUpdates(incompatible);
    }
  }, [selectedDevices, versions]);

  useEffect(() => {
    if (addRequest.fulfilled) {
      handleClose();
      setSelectedVersion("");
      dispatch(clearError({ intent: updateIntent, message: "" }));
    }
  }, [addRequest.fulfilled, handleClose, setSelectedVersion, dispatch]);

  useEffect(() => {
    if (!open) return;
    // check select count on open
    if (selected.length === 0) {
      dispatch(
        setError({
          intent: updateIntent,
          message: "Please select at least one device to update",
        })
      );
      handleClose();
    } else {
      dispatch(clearError({ intent: updateIntent, message: "" }));
      dispatch(getBundles());
      setSelectedDevices(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, JSON.stringify(selected.map((s) => s.serial_number).sort())]);

  useEffect(() => {
    if (!open || selectedVersion === "" || selectedDevices.length === 0) return;
    if (resetRequest.fulfilled) {
      // we have done a reset request from our dialog
      // lets try the update again
      dispatch(
        addUpdate({
          version: selectedVersion,
          serial_number: selectedDevices.map((v) => v.serial_number),
        })
      );
      dispatch(resetSetStateFailedOnDevicesState());
      return;
    }
    if (resetRequest.error !== undefined) {
      // reset state has some errors, we end our dialog here
      // the error will be displayed by the other dialog
      handleClose();
    }
  }, [
    resetRequest,
    open,
    dispatch,
    selectedVersion,
    selectedDevices,
    handleClose,
  ]);

  return (
    <>
      <Snackbar
        open={addRequest.fulfilled}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(resetAddUpdateState());
        }}
      >
        <Alert
          onClose={() => {
            dispatch(resetAddUpdateState());
          }}
          severity="success"
        >
          Updates created.
        </Alert>
      </Snackbar>
      <Dialog
        open={open && updateErrorList.length === 0 && !resetRequest.pending}
        onClose={handleClose}
      >
        <DialogTitle>Please choose a version to update to</DialogTitle>
        <DialogContent>
          <UpdateSelect
            setSelectedVersion={setSelectedVersion}
            updates={versions}
            compatibleUpdates={compatibleUpdates}
            incompatibleUpdates={incompatibleUpdates}
            selectedVersion={selectedVersion}
            selectedDevices={selectedDevices}
            setUpdatableDevices={setUpdatableDevices}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="warning"
            variant="contained"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              console.log(selectedVersion);
              if (selectedVersion === "") {
                dispatch(
                  setError({
                    intent: updateIntent,
                    message: "Please select a version for update.",
                  })
                );
                handleClose();
                return;
              }
              dispatch(clearError({ intent: updateIntent, message: "" }));
              dispatch(
                addUpdate({
                  version: selectedVersion,
                  serial_number: selectedDevices.map((v) => v.serial_number),
                })
              );
            }}
          >
            Assign Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open && updateErrorList.length > 0}
        onClose={closeAndResetState}
      >
        <DialogTitle>
          <Typography variant="h6">Errors While Inserting Update</Typography>
        </DialogTitle>
        <DialogContent>
          {Object.keys(updateErrorListFiltered).map((msg) => {
            if (updateErrorListFiltered[msg].length === 0) return "";
            return (
              <React.Fragment key={msg}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "left",
                    margin: "20px 0px 20px 0px",
                    textTransform: "capitalize",
                  }}
                >
                  {msg}:
                </Typography>
                <IncompTable
                  msg={msg}
                  updateErrorListFiltered={updateErrorListFiltered}
                  devices={devices}
                />
                <div>
                  {msg === UpdateInsertErrorEntryMessage.NO_BUNDLE ? (
                    <CompatibleDevices updatableDevices={updatableDevices} />
                  ) : null}
                </div>
              </React.Fragment>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            color="warning"
            variant="contained"
            onClick={closeAndResetState}
          >
            Abort
          </Button>

          {Object.keys(updateErrorListFiltered).map((msg) => {
            if (updateErrorListFiltered[msg].length === 0) return "";
            if (msg === UpdateInsertErrorEntryMessage.NO_BUNDLE) {
              return (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    dispatch(clearError({ intent: updateIntent, message: "" }));
                    dispatch(resetAddUpdateState());
                    setSelectedDevices(updatableDevices);
                    dispatch(
                      addUpdate({
                        version: selectedVersion,
                        serial_number: updatableDevices.map(
                          (v) => v.serial_number
                        ),
                      })
                    );
                  }}
                >
                  Update Compatible Devices
                </Button>
              );
            } else {
              return "";
            }
          })}

          {
            //only allow the state reset if there aren't any other error messages
            updateErrorList.length -
              updateErrorListFiltered[UpdateInsertErrorEntryMessage.WRONG_STATE]
                ?.length ===
            0 ? (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  dispatch(
                    setStateFailedOnDevices(
                      updateErrorListFiltered[
                        UpdateInsertErrorEntryMessage.WRONG_STATE
                      ].map((v) => v.serial_number)
                    )
                  );
                  dispatch(resetAddUpdateState());
                }}
              >
                Reset State and try again
              </Button>
            ) : null
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateDialog;
