import React from 'react'
import WifiIcon from "@mui/icons-material/Wifi";

type Props = {
    time: string
}

const RelativeTime = (props: Props) => {
    const { time } = props;
    const convertTime = (time: string) => {
        if (Math.abs(new Date().getTime() - new Date(time).getTime()) < 120000) {
        return <WifiIcon color="success" />;
      }
      // return a relative time string like "2 days ago"
      const date = new Date(time);
      const timeDiff = Math.abs(new Date().getTime() - date.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      const diffHours = Math.ceil(timeDiff / (1000 * 3600));
      const diffMinutes = Math.ceil(timeDiff / (1000 * 60));
      if (diffDays > 1) {
        return diffDays + " days ago";
      } else if (diffHours > 1) {
        return diffHours + " hours ago";
      } else if (diffMinutes > 2) {
        return diffMinutes + " minutes ago";
      }
    }
  return (
    <>{convertTime(time)}</>
  )
}

export default RelativeTime;