import {
  clearError,
  fetchFailed,
  getErrorID,
  reasonInvalidToken,
  setError,
  SimpleErrorData,
} from "js/actions/errors";
import { createReducer } from "@reduxjs/toolkit";

type Errors = Map<string, SimpleErrorData>;
const initialState: Errors = new Map();

const errors = createReducer(initialState, (builder) => {
  builder.addCase(fetchFailed, (state, action) => {
    if (action.payload.message === reasonInvalidToken) return;
    const id = getErrorID(action.payload);
    state.set(id, action.payload);
  });
  builder.addCase(setError, (state, action) => {
    const id = getErrorID(action.payload);
    state.set(id, action.payload);
  });
  builder.addCase(clearError, (state, action) => {
    state.delete(getErrorID(action.payload));
  });
});

export default errors;
