import { reasonInvalidToken } from "js/actions/errors";
import { Middleware } from "redux";
import { logOut } from "./auth";

const unauthorizedErrorMiddleware: Middleware = () => (next) => (action) => {
  if (action.payload?.message === reasonInvalidToken) {
    const currentPath = window.location.hash;
    const nextUrl = currentPath.slice(2);
    logOut(nextUrl);
  }
  return next(action);
};

export default unauthorizedErrorMiddleware;
