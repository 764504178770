import Box from "@mui/material/Box";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

function DeviceSearchBar() {
  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter variant="outlined" sx={{width:"100%"}} />
    </Box>
  );
}

export default DeviceSearchBar;
