import { combineReducers } from "redux";
import errors from "js/reducers/errors";
import devices from "js/reducers/devices";
import bundles from "js/reducers/bundles";
import update from "js/reducers/update";
import projects from "js/reducers/projects";

export default combineReducers({
  errors,
  devices,
  bundles,
  update,
  projects,
});
