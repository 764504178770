import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import StackItem from "components/StackItem";
import { getBundles } from "js/actions/bundles";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { BundleInfo } from "js/lib/bundleType";
import { getComparator, Order } from "js/lib/sort";
import { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import BundleVersion from "components/BundleVersion";

interface HeadCell {
  id: keyof BundleInfo;
  label: string;
}

const columns: readonly HeadCell[] = [
  { id: "file_name", label: "File Name" },
  { id: "version", label: "Version" },
  { id: "arch", label: "Architecture" },
  { id: "build_at", label: "Built At" },
  { id: "description", label: "Description" },
];

const Bundles = () => {
  const bundles = useAppSelector((state) => state.bundles.bundles);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof BundleInfo>("build_at");

  useEffect(() => {
    dispatch(getBundles());
  }, [dispatch]);

  const createSortHandler =
    (property: keyof BundleInfo) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BundleInfo
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Bundles{" "}
        <Button
          onClick={() => {
            dispatch(getBundles());
          }}
        >
          <RefreshIcon />
        </Button>
      </Typography>
      <Stack>
        <StackItem>
          {" "}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <TableContainer sx={{ maxHeight: "calc(100vh - 200px)" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sortDirection={orderBy === column.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bundles
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.arch + row.version}
                        >
                          {columns.map((column) => (
                            <TableCell key={column.id}>
                              {column.id === "version" ? (
                                <BundleVersion version={row[column.id]} />
                              ) : (
                                row[column.id]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </StackItem>
      </Stack>
    </>
  );
};

export default Bundles;
