import Chip from "@mui/material/Chip";
import { isBetaVersion } from "js/lib/bundleType";

interface BundleVersionProps {
  version: string;
  arch?: string;
}

function BundleVersion(props: BundleVersionProps) {
  return (
    <>
      {isBetaVersion(props.version) ? (
        <>
          {props.version}
          <Chip
            label="Beta"
            size="small"
            color="warning"
            sx={{ verticalAlign: "2px", ml: 1 }}
          />
        </>
      ) : (
        props.version
      )}
      {props.arch && ` (${props.arch})`}
    </>
  );
}

export default BundleVersion;
