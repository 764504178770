import { Component, ErrorInfo } from "react";
import { clearError } from "js/actions/errors";
import { useAppDispatch, useAppSelector } from "js/hooks";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";

// Display redux error state
const ErrorList = () => {
  const errors = useAppSelector(({ errors }) => errors);
  const dispatch = useAppDispatch();
  return (
    <Box sx={{ display: "flex", my: 2, flexDirection: "column", gap: 2 }}>
      {Array.from(errors).map(([_, error]) => (
        <Alert
          severity="error"
          key={error.message}
          onClose={() => dispatch(clearError(error))}
        >
          {`Failed to ${error.intent}: ${error.message}`}
        </Alert>
      ))}
    </Box>
  );
};

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Alert severity="error" sx={{ my: 2 }}>
            Something went wrong, please reload the page.
          </Alert>
        </Container>
      );
    }

    return (
      <>
        <Container className="container">
          <ErrorList />
        </Container>
        {this.props.children}
      </>
    );
  }
}
