export interface DeviceInfo {
  arch: string;
  host_name: string;
  inventory_number: string;
  description: string;
  serial_number: string;
  project: string;
  last_online_at: string;
  booted_version: string | null;
  manageable: boolean;
  current_update_state: string | null;
  current_update_version: string | null;
}

export interface DeviceUpdate
  extends Partial<Pick<DeviceInfo, "description" | "project">> {
  serial_number: string[];
  set_state_failed: boolean;
}

export const EmptyDevice: DeviceInfo = {
  arch: "",
  host_name: "",
  inventory_number: "",
  description: "",
  serial_number: "",
  project: "",
  last_online_at: "",
  booted_version: null,
  manageable: false,
  current_update_state: null,
  current_update_version: null,
};

export interface DeviceCell {
  id: keyof DeviceInfo;
  label: string;
}

export interface DeviceUpdateLog {
  percentage: number;
  message: string;
  depth: number;
  timestamp: string;
  version: string;
}
