import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { useEffect } from "react";
import { DeviceInfo } from "js/lib/deviceType";
import {
  resetSetStateFailedOnDevicesState,
  setStateFailedOnDevices,
} from "js/actions/devices";
import { clearError, resetIntent, setError } from "js/actions/errors";

type ResetUpdateStateDialogProps = {
  open: boolean;
  selected: DeviceInfo[];
  handleClose: () => void;
};

function ResetUpdateStateDialog({
  open,
  selected,
  handleClose,
}: ResetUpdateStateDialogProps) {
  const dispatch = useAppDispatch();
  const resetRequest = useAppSelector((state) => state.devices.setResetRequest);

  useEffect(() => {
    if (!open) return;
    if (selected.length === 0) {
      dispatch(
        setError({
          intent: resetIntent,
          message: "Please select at least one device to update",
        })
      );
      handleClose();
    } else {
      dispatch(clearError({ intent: resetIntent, message: "" }));
    }
  }, [open, handleClose, selected, dispatch]);

  return (
    <>
     <Snackbar
        open={resetRequest.fulfilled}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(resetSetStateFailedOnDevicesState());
        }}
      >
        <Alert
          onClose={() => {
            dispatch(resetSetStateFailedOnDevicesState());
          }}
          severity="success"
        >
          State of selected devices has been reset.
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>State reset?</DialogTitle>
        <DialogContent>
          Do you really want to set state of selected devices to Failed?
        </DialogContent>
        <DialogActions>
          <Button
            color="charcoal"
            variant="contained"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(
                setStateFailedOnDevices(
                  selected
                    .filter(
                      (v) =>
                        v.current_update_state === "Assigned" ||
                        v.current_update_state === "Updating"
                    )
                    .map((v) => v.serial_number)
                )
              );
              handleClose();
            }}
          >
            Set to Failed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResetUpdateStateDialog;
